import React from 'react';
import classnames from 'classnames';

import { SortDirection } from '../../../enums';
import Icon from '../Icon/Icon';
import { SortTriangle } from '../Icon/Icons';
import styles from './Arrows.module.scss';

export interface ArrowsProps {
  headerKey: string;
  sortDirection: SortDirection;
  sortKey?: string;
}

const Arrows: React.FC<ArrowsProps> = ({
  headerKey,
  sortDirection,
  sortKey,
}) => {
  const isHeaderActive = headerKey === sortKey;
  return (
    <div className={styles.ArrowWrapper}>
      <Icon
        icon={SortTriangle}
        color={isHeaderActive && sortDirection === SortDirection.ASC ? 'white' : 'grey'}
        className={classnames(
          styles.ArrowWrapper__Arrow,
        )} />
      <Icon
        icon={SortTriangle}
        color={isHeaderActive && sortDirection === SortDirection.DESC ? 'white' : 'grey'}
        className={classnames(
          styles.ArrowWrapper__Arrow,
          styles['ArrowWrapper__Arrow--desc'],
        )} />
    </div>
  );
};

export default Arrows;
