import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import getWeek from 'date-fns/getWeek';
import getWeekYear from 'date-fns/getWeekYear';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';

import { CalendarView, InitialDateRangePickerValue, defaultWeekOptions, weekStartsOn } from '../DateRangePicker';

interface InitialDate {
  startDate: Date | null;
  endDate: Date | null;
}

export const calculateInitialValue = (
  calendarView: CalendarView,
  value: InitialDateRangePickerValue = { startDate: null, endDate: null },
): InitialDate => {
  const initialValues: InitialDate = {
    startDate: null,
    endDate: null,
  };

  const { startDate: startValue, endDate: endValue } = value;

  if (startValue) {
    initialValues.startDate =
      calendarView === CalendarView.Month ? startOfMonth(startValue) : startOfWeek(startValue, { weekStartsOn });
  }

  if (endValue) {
    initialValues.endDate =
      calendarView === CalendarView.Month ? endOfMonth(endValue) : endOfWeek(endValue, { weekStartsOn });
  }

  return initialValues;
};

export const getDateFormat = (date: Date | null): string | null => {
  if (!date) {
    return null;
  }

  return `'Week' ${getWeek(date, defaultWeekOptions)} ${getWeekYear(date, defaultWeekOptions)}`;
};
