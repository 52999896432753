import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { GetArticles, GetArticlesVariables } from './types/GetArticles';

export type VariablesType = GetArticlesVariables;
export type ResultType = GetArticles;

export const query = gql`
  query GetArticles(
    $skip: Int
    $take: Int
    $search: String
    $sectorKey: Int
    $isHome: Boolean
  ) {
    articles(
      skip: $skip,
      take: $take,
      search: $search,
      sectorKey: $sectorKey,
      isHome: $isHome,
    ) {
      items {
        id
        body
        source
        source2
        publishedAt
        brands {
          name
        }
        country {
          id
          name
          region
        }
        sector {
          id
          name
        }
      }
      count
    }
  }`;
