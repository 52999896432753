import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { GetAllProductCategories } from './types/GetAllProductCategories';

export type ResultType = GetAllProductCategories;

export const query = gql`
  query GetAllProductCategories {
    allProductCategories {
      id
      name
      active
      accountGroupKey
    }
  }`;
