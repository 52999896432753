import React, { useRef } from 'react';
import styles from './InteractiveTileGrid.module.scss';
import useResponsiveMaxHeight from './Hooks/useResponsiveMaxHeight';
import { getConfig } from 'src/config';
import Plus from './plus.svg';

interface TileProps {
  id: number;
  title: string;
  expandedContent: string;
  tileState: 'expanded' | 'collapsed' | 'regular';
  onHover: () => void;
  onLeave: () => void;
  isOnSmallWindow: boolean;
}

const Tile: React.FC<TileProps> = ({
  id,
  title,
  expandedContent,
  tileState,
  onHover,
  onLeave,
  isOnSmallWindow,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const maxHeight = useResponsiveMaxHeight(tileState, contentRef);
  const config = getConfig();

  const handleMouseEnter = isOnSmallWindow ? undefined : onHover;
  const handleMouseLeave = isOnSmallWindow ? undefined : onLeave;

  const tileClass = `tile${id}`;
  const className = `${styles.tile} ${styles[tileClass]} ${tileState === 'expanded' ? styles.expanded : ''}`;
  const headerClass = `${styles.tileHeader} ${tileState === 'collapsed' ? styles.hidden : ''}`;
  const renderExpandedContent = () => {
    // Conditionally render expanded content or a link for tile ID 9
    if (id === 9) {
      const imageUrl = `https://${config.s3BucketName}/BoutiqueCoverageList.jpeg`;
      return <a href={imageUrl} target="_blank" rel="noopener noreferrer">{expandedContent}</a>;
    }
    return expandedContent;
  };

  return (
    <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <header className={headerClass}>{title}</header>
      <div
        ref={contentRef}
        className={styles.tileExpandedContent}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        {renderExpandedContent()}
      </div>
      <img src={Plus} className={tileState === 'expanded' ? styles.hidden : ''} alt="Plus" />
    </div>
  );
};

export default Tile;
