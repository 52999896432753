import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { SubmitContactForm, SubmitContactFormVariables } from './types/SubmitContactForm';

export type VariablesType = SubmitContactFormVariables;
export type ResultType = SubmitContactForm;

export const SUBMIT_CONTACT_FORM = gql`
  mutation SubmitContactForm($input: SubmitContactFormInput!) {
    saveFormDataAndSendVerificationEmail(input: $input) {
      success
      message
    }
  }
`;
