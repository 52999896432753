import React from 'react';
import styles from './WhiteSpace.module.scss';
import Dots from './dots.svg';
import LayoutWrapper from 'src/components/system/LayoutWrapper/LayoutWrapper';

interface WhiteSpaceProps {
  text: string;
  align: 'left' | 'right';
}

const WhiteSpace: React.FC<WhiteSpaceProps> = ({ text, align }) => {
  return (
    <div className={styles.div2}>
      <img src={Dots} alt="Dots" className={styles.backgroundImage} />
      <LayoutWrapper>
        <div className={`${styles.textContainer} ${styles[align]}`}>
          <span className={styles.text}>{text}</span>
        </div>
      </LayoutWrapper>
    </div>
  );
};

export default WhiteSpace;
