import { feedback, inputs } from '@ecosystem/newron-design-system';
import { MonthFormatType, WeekFormatType } from '@ecosystem/newron-design-system/build/exports/inputs';
import classnames from 'classnames';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import sub from 'date-fns/sub';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '../system/Icon/Icon';
import { CloseField } from '../system/Icon/Icons';
import styles from './DateRangePicker.module.scss';
import { useColorKeyboardSelected, useDateRangePickerState } from './hooks';

export enum CalendarView {
  Week = 'Week',
  Month = 'Month',
}

export type InitialDateRangePickerValue = { startDate?: Date | null; endDate?: Date | null };

export type DateRangePickerProps = {
  calendarView: CalendarView;
  weekCount?: number;
  maxDate?: Date;
  minDate?: Date;
  monthCount?: number;
  onStartRangeSelect: (startDate: Date | null) => void;
  onEndRangeSelect: (endDate: Date | null) => void;
  direction?: 'row' | 'column';
  selectedDate: InitialDateRangePickerValue;
  isClearable?: boolean;
  dateFormat?: inputs.MonthFormatType | inputs.WeekFormatType;
  testId?: string;
};

export const weekStartsOn = 0;

export const defaultWeekOptions = {
  weekStartsOn,
  firstWeekContainsDate: 3,
} as const;

export const DateRangePicker = ({
  calendarView,
  weekCount,
  maxDate,
  monthCount,
  onStartRangeSelect,
  onEndRangeSelect,
  direction = 'column',
  minDate,
  isClearable,
  selectedDate,
  dateFormat,
  testId,
}: DateRangePickerProps): JSX.Element => {
  const { Tooltip } = feedback;
  const { MonthPicker, WeekPicker } = inputs;
  const { startDate, endDate, onStartDateSelect, onEndDateSelect } = useDateRangePickerState(
    calendarView,
    onStartRangeSelect,
    onEndRangeSelect,
    selectedDate,
  );
  const { colorKeyboardSelected } = useColorKeyboardSelected(
    calendarView,
    startDate,
    endDate,
  );
  const [dateContainerFlag, setDateContainerFlag] = useState<boolean>(false);
  const [showCalendarIcon, setShowCalendarIcon] = useState<boolean>(true);
  const pickerRef = useRef<HTMLDivElement>(null);

  const maximumDate = useMemo(() => {
    return (
      maxDate &&
      (calendarView === CalendarView.Month ? endOfMonth(maxDate) : endOfWeek(maxDate, { weekStartsOn }))
    );
  },                          [maxDate, calendarView]);

  const minimumDate = useMemo(() => {
    let minimumDate = undefined;
    if (!minDate && !monthCount && !weekCount) {
      return minimumDate;
    }

    if (minDate) {
      minimumDate = minDate;
    }

    if (!minDate && (monthCount || weekCount)) {
      minimumDate = sub(
        new Date(),
        calendarView === CalendarView.Month ? { months: monthCount } : { weeks: weekCount },
      );
    }

    return (
      minimumDate &&
      (calendarView === CalendarView.Month ? startOfMonth(minimumDate) : startOfWeek(minimumDate, { weekStartsOn }))
    );
  },                          [minDate, monthCount, weekCount, calendarView]);

  useEffect(() => {
    if (!pickerRef.current) return;

    const monthPickerContainer = pickerRef.current?.querySelectorAll('[aria-labelledby="date-label"]')?.[0];
    const inputs = monthPickerContainer?.querySelectorAll('input');
    inputs?.forEach((input) => {
      input.disabled = true;
    });
  },        [dateContainerFlag]);

  useEffect(() => {
    if (!pickerRef.current) return;

    const calendarIconContainer = pickerRef.current?.querySelectorAll('[aria-labelledby="date-label"]')?.[0]?.children?.[1];
    if (showCalendarIcon) {
      calendarIconContainer?.classList.remove(styles.IconHidden);
    } else {
      calendarIconContainer?.classList.add(styles.IconHidden);

      const clearIconButton = pickerRef.current?.querySelectorAll('[data-test-id="calendar-clear-icon"]')?.[0];
      const handleMouseOver = () => {
        const toolTipContainers = document.querySelectorAll('[role="tooltip"]');
        toolTipContainers?.forEach((tooltip) => {
          tooltip.classList.add(styles.customToolTip);
        });
      };

      clearIconButton?.addEventListener('mouseover', handleMouseOver);
      return () => {
        clearIconButton?.removeEventListener('mouseover', handleMouseOver);
      };
    }
  },        [showCalendarIcon]);

  useEffect(() => {
    startDate || endDate ? setShowCalendarIcon(false) : setShowCalendarIcon(true);
  },        [startDate, endDate]);

  type RangeDates = [Date | undefined, Date | undefined];
  const handleDateChange = ([startDate, endDate]: RangeDates) => {
    if (startDate === undefined || (maximumDate && startDate > maximumDate) || (minimumDate && startDate < minimumDate)) {
      // tslint:disable-next-line:no-parameter-reassignment
      startDate = undefined as unknown as Date;
      onStartDateSelect(startDate);
    } else if (startDate !== undefined) {
      onStartDateSelect(startDate);
    }

    if (endDate === undefined || (maximumDate && endDate > maximumDate) || (minimumDate && endDate < minimumDate)) {
      // tslint:disable-next-line:no-parameter-reassignment
      endDate = undefined as unknown as Date;
      onEndDateSelect(endDate);
    } else if (endDate !== undefined) {
      onEndDateSelect(endDate);
    }
  };

  const handleOnFocus = () => {
    setShowCalendarIcon(false);
  };

  const handleDateClear = () => {
    const undefinedDate = undefined as unknown as Date;
    setDateContainerFlag(!dateContainerFlag);
    setShowCalendarIcon(true);
    onStartDateSelect(undefinedDate);
    onEndDateSelect(undefinedDate);
  };

  const renderClearButton = () => (
    <button data-test-id={'calendar-clear-icon'} onClick={handleDateClear} className={styles.ClearIconBtn}>
      <Tooltip placement="bottom" theme="dark">
        <Icon icon={CloseField} size={'24'} color={'dark-orange'} />
        <Tooltip.Content>
          <p>Clear Date</p>
        </Tooltip.Content>
      </Tooltip>
    </button>
  );

  return (
    <div
      data-test-id="DateRangePickerContainer"
      className={classnames(styles.DateRangePickerContainer, colorKeyboardSelected && styles.KeyboardSelectedMonth)}
      ref={pickerRef}
    >
      <div className={classnames('flex flex-flow-col auto-cols-max gap-2')}>
        <div
          key={`date-range-picker-container ${dateContainerFlag ? 'true' : 'false'}`}
          data-test-id={testId}
          className={classnames(styles.DatePickerGridCell, direction === 'row' && styles.DatePickerGridCellRow)}
        >
          {calendarView === CalendarView.Month ? (
            <MonthPicker
              isRange
              primaryPlaceholder={'Start Date'}
              secondaryPlaceholder={'End Date'}
              onChange={handleDateChange}
              onFocus={handleOnFocus}
              minDate={minimumDate}
              maxDate={maximumDate}
              dateFormat={dateFormat as MonthFormatType}
              defaultDates={[startDate || undefined, endDate || undefined]}
            />
          ) : (
            <WeekPicker
              isRange
              primaryPlaceholder={'Week Year'}
              secondaryPlaceholder={'Week Year'}
              onChange={handleDateChange}
              onFocus={handleOnFocus}
              minDate={minimumDate}
              maxDate={maximumDate}
              dateFormat={dateFormat as WeekFormatType}
              defaultDates={[startDate || undefined, endDate || undefined]}
            />
          )}
          {isClearable && !showCalendarIcon && renderClearButton()}
        </div>
      </div>
    </div>
  );
};
