import React, { useState, useCallback } from 'react';
import Tile from './Tile';
import styles from './InteractiveTileGrid.module.scss';

// tslint:disable-next-line:prefer-array-literal
const Column: React.FC<{ tiles: Array<{ id: number; title: string, expandedContent: string }>, isOnSmallWindow: boolean }>
= ({ tiles, isOnSmallWindow }) => {
  const [expandedTileId, setExpandedTileId] = useState<number | null>(null);

  const handleHover = useCallback((id: number) => {
    setExpandedTileId(id);
  },                              []);

  const handleLeave = useCallback(() => {
    setExpandedTileId(null);
  },                              []);

  return (
    <div className={styles.column}>
      {tiles.map((tile) => {
        let tileState: 'expanded' | 'collapsed' | 'regular' = 'regular';
        if (isOnSmallWindow) {
          tileState = 'expanded';
        } else if (tile.id === expandedTileId) {
          tileState = 'expanded';
        } else if (expandedTileId !== null) {
          tileState = 'collapsed';
        }

        return (
          <Tile
            key={tile.id}
            id={tile.id}
            title={tile.title}
            expandedContent={tile.expandedContent}
            tileState={tileState}
            onHover={() => handleHover(tile.id)}
            onLeave={handleLeave}
            isOnSmallWindow={isOnSmallWindow}
          />
        );
      })}
    </div>
  );
};

export default Column;
