import React from 'react';
import { default as ReactSelect, Props }  from 'react-select';
import './Select.scss';
import Label, { labelIdentifier } from '../Label/Label';

interface SelectProps extends Props {
  label?: string;
}

const Select: React.FC<SelectProps> = (props) => {
  const id = props.label ? labelIdentifier(props.label) : '';

  return (
    <>
      {props.label && <Label for={id} text={props.label} />}
      <ReactSelect
        {...props}
        inputId={id}
        classNamePrefix="Select" />
    </>
  );
};

export default Select;
