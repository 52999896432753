import React from 'react';
import ReleaseTable from '../../ReleaseTable/ReleaseTable';
const Dashboard: React.FC = () => {

  return (
    <>
      <ReleaseTable showHistory={true} isGrouped shouldShowFilters />
    </>
  );
};

export default Dashboard;
