import classnames from 'classnames';
import React, { ReactNode, useState } from 'react';
import Icon from '../Icon/Icon';
import { ChevronDown, ChevronUp } from '../Icon/Icons';
import styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  selected?: number;
  activeTitle?: string;
  nested?: boolean;
  onClick?: (title: string | undefined) => void;
  startsOpen?: boolean;
  titleId?: string;
}

interface AccordionTitleProps {
  title: string;
  activeTitle?: string;
  isOpen: boolean;
  id?: string;
}

interface AccordionSubtitleProps {
  selected?: number;
}

const AccordionTitle: React.FC<AccordionTitleProps> = (props) => {
  if (props.isOpen && props.activeTitle) {
    return <span id={props.id} className={styles.Accordion__title}>{props.activeTitle}</span>;
  }
  return <span id={props.id} className={styles.Accordion__title}>{props.title}</span>;
};

const AccordionSubtitle: React.FC<AccordionSubtitleProps> = (props) => {
  if (props.selected === 0 || props.selected === undefined) return null;

  return <span className={styles.Accordion__subtitle}> ({props.selected} selected)</span>;
};

const renderChildren = (children: ReactNode, isOpen?: boolean) => {
  if (!isOpen) return;
  return (<div className={styles.Accordion__content}>
    {children}
  </div>);
};

export const Accordion: React.FC<AccordionProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!props.startsOpen);

  const accordionClasses = classnames({
    [styles['Accordion']]: true,
    [styles['Accordion--open']]: isOpen,
    [styles['Accordion--nested']]: props.nested,
  });

  return <div className={accordionClasses}>
    <div className={styles.Accordion__header}>
      <button
        className={styles.Accordion__button}
        data-test-id={`accordion-${props.title}`}
        onClick={() => {
          props.onClick && props.onClick(isOpen ? props.activeTitle : props.title);
          setIsOpen(!isOpen);
        }}>
        <Icon
          icon={isOpen ? ChevronUp : ChevronDown}
          className={styles.Accordion__chevron}
          hasHover
        />
        <div>
          <AccordionTitle title={props.title} isOpen={isOpen} activeTitle={props.activeTitle} id={props.titleId}/>
        </div>

      </button>
      <AccordionSubtitle selected={props.selected}/>
    </div>
    {renderChildren(props.children, isOpen)}
  </div>;
};
