import { Converter } from './types';

export function getCurrentQueryString(): string {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.toString();
}

export function makeQueryStringWithCurrentStateValue<T>(state: T, key: string, converter: Converter<T>): string {
  const searchParams = new URLSearchParams(window.location.search);
  const stringValue = converter.toString(state);
  if (stringValue) {
    searchParams.set(key, stringValue);
  } else {
    searchParams.delete(key);
  }
  return searchParams.toString();
}

export function getValueFromQueryString<T>(key: string, converter: Converter<T>): T {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get(key);
  return converter.fromString(value);
}
