import React, { ReactElement } from 'react';
import styles from './GridContainer.module.scss';
import classnames from 'classnames';

type GridContainerProps = {
  children: ReactElement | ReactElement[];
  gap?: boolean | string;
  container?: boolean;
  item?: boolean;
  desktop?: boolean | string;
  tablet?: boolean | string;
  justifyItems?: string;
  alignItems?: string;
  justifyContent?: string;
  alignContent?: string;
  className?: string;
};

const GridContainer = ({
  children,
  container,
  item,
  justifyContent,
  justifyItems,
  alignContent,
  alignItems,
  className,
  gap = false,
  tablet = false,
  desktop = false,
}: GridContainerProps) => {

  return (
    <div className={
      classnames(
        className,
        {
          [styles.Container]: container,
          [styles.Container__Item]: item,
          [styles[`Container__tablet-col${tablet}`]]: tablet !== false,
          [styles[`Container__desktop-col${desktop}`]]: desktop !== false,
          [styles[`Container__gap-${gap}`]]: gap !== false,
          [styles[`Container__justifyItems-${justifyItems}`]]: justifyItems,
          [styles[`Container__justifyContent-${justifyContent}`]]: justifyContent,
          [styles[`Container__alignItems-${alignItems}`]]: alignItems,
          [styles[`Container__alignContent-${alignContent}`]]: justifyContent,
        })
    }>{children}</div>
  );
};

export default GridContainer;
