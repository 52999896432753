import React from 'react';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import WorldBanner from './Components/WorldImageBanner/WorldBanner';
import InteractiveTileGrid from './Components/InteractiveTileGrid/InteractiveTileGrid';
import OverviewText from './Components/OverviewText/OverviewText';
import StatSection from './Components/StatSection/StatSection';
import TileGrid from './Components/TileGrid/TileGrid';
import WhiteSpace from './Components/WhiteSpace/WhiteSpace';
import styles from './Landing.module.scss';
interface LandingProps {
  toggleLoginModal: () => void;
  toggleContactModal: () => void;
}

const Landing: React.FC<LandingProps> = ({ toggleLoginModal, toggleContactModal }) => {
  return (
    <div className={styles.landingContainer}>
      <WorldBanner/>
      <OverviewText toggleLoginModal={toggleLoginModal} toggleContactModal={toggleContactModal}/>
      <StatSection/>
      <WhiteSpace text="GfK Boutique Research captures proprietary sales data in over 300 product categories, including:" align="left" />
      <InteractiveTileGrid/>
      <WhiteSpace text="GfK Boutique: An expert on investment insights in the consumer technology and durables market" align="right" />
      <TileGrid/>
    </div>
  );
};

export default Landing;
