import React from 'react';
import { useParams } from 'react-router-dom';
import ReleaseTable from '../../ReleaseTable/ReleaseTable';

interface DeliverableDownloadParams {
  releaseKey: string;
  deliverableKey: string;
}

const DeliverableDownload: React.FC = () => {
  const { releaseKey, deliverableKey } = useParams<DeliverableDownloadParams>();
  return <ReleaseTable releaseKey={+releaseKey} deliverableKey={+deliverableKey} />;
};

export default DeliverableDownload;
