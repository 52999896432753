import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getConfig, isProduction } from '../config';

export function createApolloClient() {
  const adminUrlPrefix = isProduction() ? 'https://' : 'http://';
  const adminUrl = adminUrlPrefix + getConfig().adminAppUrl;

  // trim any trailing slashes
  const uri = `${adminUrl.replace(/\/+$/, '')}/graphql`;

  const httpLink = createHttpLink({ fetch, uri, credentials: 'include' });

  const authLink = setContext(async (_, { headers }) => {
    return ({
      headers: {
        ...headers,
      },
    });
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'none',
      },
    },
  });
}
