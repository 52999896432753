/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import EcosystemLogo from '../EcosystemLogo/EcosystemLogo';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import styles from './EcosystemHeader.module.scss';
import classNames from 'classnames';

interface EcosystemHeaderProps {
  email: string;
  isAuthenticated: boolean;
  logout: () => void;
  simplified?: boolean;
  headerLinks?: [{ text: string, href: string }];
  currentPath: string;
  toggleLoginModal?: () => void;
  toggleContactModal?: () => void;
}

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },        []);

  return windowWidth;
}

const EcosystemHeader: React.FC<EcosystemHeaderProps> = ({
  email,
  isAuthenticated,
  logout,
  simplified,
  headerLinks,
  currentPath,
  toggleLoginModal,
  toggleContactModal,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownToggleRef = useRef<HTMLDivElement | null>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const handleClickOutsideModal = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
       !dropdownToggleRef.current?.contains(event.target as Node)) {
        setIsMenuVisible(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutsideModal);
    return () => {
      window.removeEventListener('mousedown', handleClickOutsideModal);
    };
  },        []);

  const renderUserNavigation = () => {
    if (simplified) return null;
    if (isAuthenticated) {
      return (
        <div className={styles.EcosystemHeader__EcosystemHeaderWrapper__Login}>
          <span data-test-id={'homes'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
            <a href="/home">Home</a>
          </span>
          <span data-test-id={'releases'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
            <a href="/releases">Releases</a>
          </span>
          <span data-test-id={'news'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
            <a href="/news">News</a>
          </span>
          <span data-test-id={'userEmail'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
            <a href="/my-account">My Account</a>
          </span>
          <button data-test-id={'logout'} id={'logout'} onClick={logout} className={styles.logout}>
            Logout
          </button>
        </div>
      );
    }
    return null;
  };

  const renderDynamicLinks = () => {
    if (simplified) return null;
    if (isAuthenticated) {
      return (
        <div className={classNames(styles.EcosystemHeader__EcosystemHeaderWrapper__links, { [styles.show]: isMenuVisible })}>
          {headerLinks?.map((headerLink) => (
            <a className={styles.EcosystemHeader__EcosystemHeaderWrapper__HeaderLink} href={headerLink.href} key={headerLink.text}>
              {headerLink.text}
            </a>
          ))}
        </div>
      );
    }
    if (currentPath !== '/login') {
      return (
        <div className={styles.EcosystemHeader__LoginLink}>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleLoginModal?.(); }}>Login</a>
          <span style={{ color: 'black', margin: '0 10px', fontWeight: 'bolder' }}>|</span>
          <a href="#" onClick={(e) => { e.preventDefault(); toggleContactModal?.(); }}>Sign Up</a>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.EcosystemHeader}>
      <LayoutWrapper>
        <div className={styles.EcosystemHeader__EcosystemHeaderWrapper}>
            <a className={styles.EcosystemHeader__EcosystemHeaderWrapper__Logo} href="/">
              <EcosystemLogo title="GFK Logo" width={100} height={78} />
              <div className={styles['EcosystemHeader__EcosystemHeaderWrapper__Logo-text']}>
                <span className={styles['EcosystemHeader__EcosystemHeaderWrapper__Logo-text--orange']}>gfk</span>
                <span className={styles['EcosystemHeader__EcosystemHeaderWrapper__Logo-text--black']}>boutique</span>
              </div>
            </a>
          <div className={classNames(styles.dropdownContainer, { [styles.showMenu]: isMenuVisible })}>
            {(isAuthenticated && !simplified && windowWidth <= 380) && (
              <div ref={dropdownToggleRef} className={styles.dropdownToggle} onClick={() => setIsMenuVisible(!isMenuVisible)}>
                <div className={styles.dropdownToggleLine}></div>
                <div className={styles.dropdownToggleLine}></div>
                <div className={styles.dropdownToggleLine}></div>
              </div>
              )}
            <div ref={dropdownRef} className={`${styles.EcosystemHeader__EcosystemHeaderWrapper__links} ${isMenuVisible && window.innerWidth <= 890 ? styles.show : ''}`}>
              {renderUserNavigation()}
              {renderDynamicLinks()}
            </div>
          </div>
        </div>
      </LayoutWrapper>
    </div>
  );
};

export default EcosystemHeader;
