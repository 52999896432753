/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DeliverableType {
  Data = "Data",
  Notes = "Notes",
}

export enum PackageFrequency {
  Biweekly = "Biweekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Weekly = "Weekly",
}

export enum PackageType {
  Core = "Core",
  Supplemental = "Supplemental",
}

export enum ReleaseType {
  Adhoc = "Adhoc",
  BiWeekly = "BiWeekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Weekly = "Weekly",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SubscriptionDeliverableType {
  Data = "Data",
  DataAndNotes = "DataAndNotes",
  Notes = "Notes",
}

export enum SubscriptionFrequency {
  Biweekly = "Biweekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Weekly = "Weekly",
}

export interface SubmitContactFormInput {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  countryRegion: string;
  companyName: string;
  reasonForContact: string;
  message: string;
  jobRole: string;
  department: string;
  industry: string;
  consentToMarketing: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
