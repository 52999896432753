import React from 'react';
import styles from './Loading.module.scss';
import classnames from 'classnames';

interface Props {
  height?: string;
  stretch?: boolean;
  compact?: boolean;
}

const Loading: React.FC<Props> = ({ height = 'auto', stretch, compact }) => {

  const css = classnames(
    styles.Loading,
    {
      [styles['Loading--stretch']]: stretch,
      [styles['Loading--compact']]: compact,
    },
  );

  const elHeight = stretch ? '100%' : height;

  return (
    <div className={css} style={{ height: elHeight }}>
      <svg className={styles.Loading__Dots} viewBox="0 0 28 7" width="28" height="7">
        <circle className={styles.Loading__Dots__Dot} cx="3.5" cy="3.5" r="3.5"/>
        <circle className={styles.Loading__Dots__Dot} cx="13.5" cy="3.5" r="3.5"/>
        <circle className={styles.Loading__Dots__Dot} cx="23.5" cy="3.5" r="3.5"/>
      </svg>
    </div>
  );
};

export default Loading;
