import React from 'react';
import Column from './Column';
import styles from './InteractiveTileGrid.module.scss';
import useCheckIfOnSmallWindow from './Hooks/useCheckIfOnSmallWindow';
import LayoutWrapper from 'src/components/system/LayoutWrapper/LayoutWrapper';

const InteractiveTileGrid: React.FC = () => {
  const isOnSmallWindow = useCheckIfOnSmallWindow(1200);

  const tiles = [
    { id: 1, title: 'Smartphones and Wireless Components', expandedContent: 'ams AG, Apple, Broadcom, Cirrus Logic, EgisTec, FocalTech, HMD Nokia, Honor, Huawei, Hynix, LG, MediaTek, Micron, Motorola, Murata, Omnivision, OnePlus, Oppo, Qorvo, Qualcomm, Realme, Samsung, Skyworks, Sony, Synaptics, Transsion, Vivo, Xiaomi' },
    { id: 2, title: 'PCs, Printers and IT Peripherals', expandedContent: 'Acer, AMD, Apple, ARM, Asus, Brother, Canon, Compal, Corsair, Dell, Edifier, Epson, GN NORD, Google, HPQ, Huawei, Intel, Inventec, Kye, Kyocera, Lenovo, Lexmark, LG, Logitech, Marvell, Micron, Microsoft, MSI, Nintendo, NVIDIA, Oki, Panasonic, Philips, Plantronics, Quanta, Razer, Samsung, Seagate, Sennheiser, Sharp, Silicon Motion, SK Hynix, Sonos, Sony, Toshiba, Turtle Beach, Western Digital, Wistron, Xiaomi, Yamaha' },
    { id: 3, title: 'TVs, Monitors & Supply Chain', expandedContent: 'ACG, AU Optronics, BOE, Changhong, Corning Glass, Fujifilm, Haier, Hisense, Innolux, Konka, LG Display, LG Electronics, Merck KGaA, Nippon Electric Glass, Novatek, Panasonic, Samung Electronics, Sharp, Skyworth, Sony, Sumitomo Chemical, TCL Electronics, Toshiba, Xiaomi' },
    { id: 4, title: 'Gaming Software & Hardware', expandedContent: 'Activision, Bandai Namco, Electronic Arts, Konami, Microsoft, Nintendo, Sega, Sony, Take-Two, Ubisoft' },
    { id: 5, title: 'Tires', expandedContent: 'Bridgestone, Continental, Dunlop, Goodyear, Hankook, Hercules, Ironman, Linglong Tire, Michelin, Nokian, Pirelli, Sumitomo Rubber' },
    { id: 6, title: 'Software, Networking & Storage', expandedContent: 'Adobe, Asus, Autodesk, Check Point Software, Cisco, Citrix, Commscope, Commvault, Dell, Extreme, Fortinet, HP Enterprise, IBM, Juniper Networks, McAfee, Microsoft, NetApp, Netgear, Nutanix, Oracle, Pure Storage, Samsung, Seagate, SolarWinds, Ubiquiti, Western Digital' },
    { id: 7, title: 'Home Appliances', expandedContent: 'Arçelik, Aux, Bosch-Siemens, Daikin, Delonghi, Electrolux, Gree, Groupe SEB, Haier, Hisense, iRobot, Joyoung, LG, Midea, P&G, Panasonic, Philips, Robam, Samsung, Vatti, Vestel, Whirlpool, Xiaomi' },
    { id: 8, title: 'Music Streaming', expandedContent: 'Sony, Spotify, Universal Music Group, Warner Music Group' },
    { id: 9, title: 'And many more...', expandedContent: 'View our coverage list here' },
  ];

  const columns = isOnSmallWindow
    ? [tiles]
    : [
      tiles.slice(0, 3),
      tiles.slice(3, 6),
      tiles.slice(6),
    ];

    return (

      <LayoutWrapper>
        <div className={styles.grid}>
          {columns.map((columnTiles) => (
            <Column
              key={`column-${columnTiles.map((tile) => tile.id).join('-')}`}
              tiles={columnTiles}
              isOnSmallWindow={isOnSmallWindow}
            />
          ))}
        </div>
      </LayoutWrapper>
    );
};

export default InteractiveTileGrid;
