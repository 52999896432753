import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import Button from 'src/components/system/Button/Button';
import styles from './AboutGfkCards.module.scss';
import { getConfig } from 'src/config';

type AboutGfkCardsProps = {
  onMoreButtonClick: () => void;
};

const AboutGfkCards: React.FC<AboutGfkCardsProps> = ({ onMoreButtonClick }) => {
  const config = getConfig();

  return (
    <div className={styles.card_container}>
      <Card className={styles.card_item}>
        <CardMedia
          className={styles.cardMedia}
          component="img"
          alt="About"
          image={`https://${config.s3BucketName}/about_team.webp`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <CardContent className={styles.cardContent}>
          <Typography gutterBottom variant="h5" component="div">
            About GfK Boutique Research
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Find out more about the power of GfK data and how we support investment firms
          </Typography>
          <br />
          <Button onClick={onMoreButtonClick}>
            More
          </Button>
        </CardContent>
      </Card>

      <Card className={styles.card_item}>
        <CardMedia
          className={styles.cardMedia}
          component="img"
          alt="Coverage"
          image={`https://${config.s3BucketName}/coverage.webp`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <CardContent className={styles.cardContent}>
          <Typography gutterBottom variant="h5" component="div">
            Our Coverage
          </Typography>
          <Typography variant="body2" color="textSecondary">
            GfK has an extensive coverage list - spanning from Consumer Technology and Home Appliances to smaller Consumer segments
          </Typography>
          <br />
          <Button onClick={() => window.open(`https://${config.s3BucketName}/BoutiqueCoverageList.jpeg`, '_blank')}>
            Take a look
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default AboutGfkCards;
