import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import * as React from 'react';
import styles from './authenticator.module.scss';
import ForgotPassword from './forgotPassword';
import Login from './Login';
import RequireNewPassword from './requireNewPassword';
import VisitorMessage from './VisitorMessage';

export type AuthState = 'signIn' | 'signedIn' | 'signedOut' | 'requireNewPassword' | 'forgotPassword';

interface IState {
  authState: AuthState | 'loading';
  authData?: any;
  error?: Error | null;
}

interface IProps {
  onAuthStateChange?: (event: AuthState, authData?: any) => any;
}

export default class Authenticator extends React.PureComponent<IProps, IState> {
  constructor(props: {}) {
    super(props);

    this.handleStateChange = this.handleStateChange.bind(this);

    this.state = { authState: 'loading' };

    Hub.listen('auth', (data) => {
      const { payload } = data;

      if (payload.event === 'signOut') {
        this.handleStateChange('signedOut');
      }
    });
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then(user => this.handleStateChange('signedIn', user))
      .catch(() => this.handleStateChange('signedOut'));
  }

  handleStateChange(state: AuthState, data?: any) {
    this.setState({ authState: state, authData: data, error: null });

    if (this.props.onAuthStateChange) {
      this.props.onAuthStateChange(state, data);
    }
  }

  content() {
    const { authState, authData } = this.state;

    switch (authState) {
      case 'signedOut':
      case 'signIn':
        return (
          <div>
            <div className={styles.authenticationContent}>
              <Login onStateChange={this.handleStateChange} />
            </div>
            <div className={styles.authenticationContent}>
              <VisitorMessage />
            </div>
          </div>
        );

      case 'signedIn':
        return null;
      case 'requireNewPassword':
        return (
          <div className={styles.authenticationContent}>
            <RequireNewPassword authData={authData} onStateChange={this.handleStateChange} />
          </div>
        );
      case 'forgotPassword':
        return (
          <div className={styles.authenticationContent}>
            <ForgotPassword onStateChange={this.handleStateChange} />
          </div>
        );
      case 'loading':
        return <div>loading...</div>;
      default:
        throw new Error(`Unexpected  auth state: '${authState}'`);
    }
  }

  render() {
    const authenticationComponent = this.content();
    const content = authenticationComponent ? <>{authenticationComponent}</> : this.props.children;

    return <div className={styles.authenticationContainer}>{content}</div>;
  }
}
