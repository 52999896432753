import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Authenticator from '../../auth/authenticator';

interface LoginModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, toggle }) => {
  const handleAuthStateChange = (state: string, data?: any) => {
    if (state === 'signedIn') {
      toggle(); // Close modal when user is signed in
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <Authenticator onAuthStateChange={handleAuthStateChange} />
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;
