import classnames from 'classnames';
import React, { forwardRef } from 'react';
import Label, { labelIdentifier } from '../Label/Label';
import styles from './Input.module.scss';

interface Props {
  id?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  value?: string | number;
  defaultValue?: string;
  onFocus?: () => void;
  type?: string;
  prepend?: string;
  disabled?: boolean;
  name?: string;
}

export type Ref = HTMLInputElement;

const Input = forwardRef<Ref, Props>((props, ref) => {
  const id = props.id || labelIdentifier(props.label!);

  const inputClasses = classnames(
    styles['Input__field'],
    { [styles['Input__field--error']]: props.error },
    { [styles['Input__field--prepended']]: props.prepend },
  );

  return (
    <div className={styles['Input']}>
      {props.label &&
      <Label for={id} text={props.label} />}
      {props.error && (
        <span className={styles['Input__error']}>{props.error}</span>
      )}
      <div className={styles['Input__wrapper']}>
        <input
          disabled={props.disabled ? props.disabled : false}
          id={id}
          ref={ref}
          placeholder={props.placeholder}
          type={props.type || 'text'}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          onFocus={props.onFocus}
          defaultValue={props.defaultValue}
          className={inputClasses}
          name={props.name}/>
        {props.prepend && <span className={styles['Input__prepend']}>{props.prepend}</span>}
      </div>
    </div>
  );
});

export default Input;
