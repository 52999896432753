import React from 'react';
import Tile from './Tile';
import styles from './TileGrid.module.scss';

// tslint:disable-next-line:prefer-array-literal
const Column: React.FC<{ tiles: Array<{ id: number; title: string, content: string }> }>
= ({ tiles }) => {

  return (
    <div className={styles.column}>
      {tiles.map((tile) => {
        return (
          <Tile
            key={tile.id}
            id={tile.id}
            title={tile.title}
            content={tile.content}
          />
        );
      })}
    </div>
  );
};

export default Column;
