import React, { useRef } from 'react';
import styles from './TileGrid.module.scss';

interface TileProps {
  id: number;
  title: string;
  content: string;
}

const Tile: React.FC<TileProps> = ({
  id,
  title,
  content,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const tileClass = `tile${id}`;
  const className = `${styles.tile} ${styles[tileClass]}`;

  return (
    <div className={className}>
      <header className={styles.tileHeader}>{title}</header>
      <div
        ref={contentRef}
        className={styles.tileContent}
      >
        {content}
      </div>
    </div>
  );
};

export default Tile;
