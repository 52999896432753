import React, { ReactElement, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { FaInfoCircle, FaFilePdf } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loading from '../system/Loading/Loading';
import Table from '../system/Table/Table';
import InlineAlert, { InlineAlertType } from '../system/InlineAlert/InlineAlert';
import { ColumnConfig } from '../system/Table/type';
import Pagination from '../system/Pagination/Pagination';
import { formatDate } from '../../utilities/formatter';
import { SortDirection, DeliverableType, PackageType } from '../../graphql/globalTypes'; // PackageFrequency
import * as getReleasesForClient from '../../graphql/getReleasesForClient';
import * as getProductCategoriesForClient from '../../graphql/getProductCategoriesForClient';
import * as getCombinedReleases from '../../graphql/getCombinedReleases';
import * as qsConverters from './queryStringTypeConverters';
import { GetReleasesForClient_releaseLogsForClient_items } from '../../graphql/types/GetReleasesForClient';
import { GetArchives_archives_items } from '../../graphql/types/GetArchives';
import {
  GetGroupedReleasesForClient_releaseLogsByRelease_items,
  GetGroupedReleasesForClient_releaseLogsByRelease_items_deliverable,
  GetGroupedReleasesForClient_releaseLogsByRelease_items_file,
} from '../../graphql/types/GetGroupedReleasesForClient';
import styles from './ReleaseTable.module.scss';
import FileDownloadLink from '../FileDownloadLink/FileDownloadLink';
import { ProductType, ReleaseType } from '../../enums';
import Filters, { SelectOption } from './Filters';
import { ArchiveDownloadLink } from '../ArchiveDownload/ArchiveDownload';
import { ValueType } from 'react-select/src/types';
import { useQueryStringState } from './queryStrings/useQueryStringState';
import { QueryStringStateDetails } from './queryStrings/types';
import { batchUpdate } from './queryStrings/batchUpdate';
interface ReleaseTableProps {
  heading?: string;
  showHistory?: boolean;
  releaseKey?: number;
  deliverableKey?: number;
  isGrouped?: boolean;
  shouldShowFilters?: boolean;
  releaseFrequencyType?: ReleaseType;
}

type ReleaseData = GetReleasesForClient_releaseLogsForClient_items;
type GroupedReleaseData = GetGroupedReleasesForClient_releaseLogsByRelease_items;

type FormattedGroupedReleaseData = Omit<
  GetGroupedReleasesForClient_releaseLogsByRelease_items,
  'file' | 'isSupplemental' | 'packageName' | 'deliverable'
> & {
  ids: number[];
  files: (GetGroupedReleasesForClient_releaseLogsByRelease_items_file | null)[];
  isSupplementals: (boolean | null)[];
  packageNames: string[];
  deliverables: GetGroupedReleasesForClient_releaseLogsByRelease_items_deliverable[];
  packageKeys: number[];
};

type FormattedArchivesData =
Omit<GetArchives_archives_items, 'filename'>
  & {
    ids: number[];
    filenames: (string | null)[];
    packageTypes: (PackageType | null)[];
    packageNames: string[];
    packageKeys: number[];
  };

interface IReleaseDataRow {
  [index: string]: any;
}

type ReleaseDataRow = IReleaseDataRow & {
  publishedAt: any;
  productCategory: string;
  productType: ProductType;
  releaseFrequency: string;
  release: string;
  fileLink?: ReactElement;
  summaryLink?: ReactElement;
};

type GroupedReleaseDataRow = IReleaseDataRow & {
  publishedAt: any;
  productCategory: string;
  productType: ReactElement[];
  release: ReactElement[];
  releaseFrequency: string;
  fileLink?: ReactElement;
  summaryLink?: ReactElement;
  duplicateCheck?: string[];
};

type Archive = GetArchives_archives_items;

interface IArchiveDataRow {
  [index: string]: any;
}

type ArchiveDataRow = IArchiveDataRow & {
  publishedAt: any;
  productCategory: string;
  productName: ReactElement[];
  productType: ReactElement[];
  frequency: string;
  filelink?: ReactElement;
};
export const queryStringKeys = {
  productCategoryKey: 'pC',
};
const ReleaseTable: React.FC<ReleaseTableProps> = (props) => {

  const { releaseKey, deliverableKey, isGrouped, shouldShowFilters } = props;
  const [sortBy, setSortBy] = useState<string>('release.publishedAt');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);
  const [skip, setSkip] = useQueryStringState<number>(0, 'sk', qsConverters.skipQsConverter);
  const [take, setTake] = useQueryStringState<number>(10, 'tk', qsConverters.takeQsConverter);
  const [releases, setReleases] = useState<ReleaseData[]>([]);
  const [archives, setArchives] = useState<Archive[]>([]);
  const [groupedReleases, setGroupedReleases] = useState<GroupedReleaseData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // tslint:disable-next-line:max-line-length
  const [packageKeys, setPackageKeys] = useQueryStringState<number[] | undefined>(undefined, 'pkg', qsConverters.packageKeysQsConverter);
  // tslint:disable-next-line:max-line-length
  const [productCategoryKey, setProductCategoryKey] = useQueryStringState<number | undefined>(undefined, queryStringKeys.productCategoryKey, qsConverters.prodCatQsConverter);
  const [isSupplemental, setIsSupplemental] = useQueryStringState<boolean | null>(null, 'sup', qsConverters.isSupQsConverter);
  const [releaseType, setReleaseType] = useQueryStringState<ReleaseType | null>(null, 'rT', qsConverters.releaseTypeQsConverter);
  const [dateRangeEnd, setDateRangeEnd] = useQueryStringState<Date | null>(null, 'eD', qsConverters.dateTimeQsConverter);
  const [dateRangeStart, setDateRangeStart] = useQueryStringState<Date | null>(null, 'sD', qsConverters.dateTimeQsConverter);
  // tslint:disable-next-line:max-line-length
  const [currentlyShowingHistoryOfARelease, setcurrentlyShowingHistoryOfARelease] = useQueryStringState<boolean>(false, 'hist', qsConverters.boolQsConverter);
  const [packageNamesStringForHistoryHeader, setPackageNamesStringForHistoryHeader] = useQueryStringState<string | undefined>(undefined, 'header', qsConverters.headerQsConverter);
  const [productCategoryFilterOptions, setProductCategoryFilterOptions] = useState<SelectOption[]>([]);
  const toggleModalVisibility = () => setIsModalVisible(!isModalVisible);

  const [combinedReleaseCount, setCombinedReleaseCount] = useState<number>(1);

  // tslint:disable-next-line:max-line-length
  const [releaseDataQuery, releaseData] = useLazyQuery<getReleasesForClient.ResultType, getReleasesForClient.VariablesType>(getReleasesForClient.query, {
    onCompleted: (data) => {
      setReleases(data?.releaseLogsForClient?.items);
    },
  });

  // tslint:disable-next-line:max-line-length
  const [combinedReleasesQuery, combinedReleasesQueryData] = useLazyQuery<getCombinedReleases.ResultType, getCombinedReleases.VariablesType>(getCombinedReleases.query, {
    onCompleted: (data) => {
      setGroupedReleases(data?.combinedReleases.releaseLogs);
      setCombinedReleaseCount(data?.combinedReleases.count);
      setArchives(data?.combinedReleases.archives);
    },
  });

  const [productCategoriesQuery, productCategoriesQueryData] =
  useLazyQuery<getProductCategoriesForClient.ResultType>(getProductCategoriesForClient.query, {
    onCompleted: (data) => {
      const productCategories = data?.productCategoriesForClient?.map(({ id, name }) =>
      ({ value: id.toString(), label: name }));
      setProductCategoryFilterOptions(productCategories);
    },
  });

  useEffect(() => {
    if (!isGrouped && !releases.length) {
      releaseDataQuery({
        variables: {
          skip,
          take,
          sortBy,
          sortDirection,
          releaseKey,
          deliverableKey,
          productCategoryKey,
          releaseType,
        },
      });
      return;
    }
    const pageNumber = Math.ceil(skip / take) + 1;
    onPagingChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [sortDirection, take]);

  useEffect(() => {
    isGrouped && combinedReleasesQuery({variables: {
      skip,
      take,
      productCategoryKey,
      packageKeys,
      sortBy,
      sortDirection,
      releaseType,
      dateRangeEnd,
      dateRangeStart,
      packageTypes: isSupplemental !== null ? [isSupplementalToPackageType(isSupplemental)] : null,
    }});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [
    productCategoryKey,
    packageKeys,
    isSupplemental,
    releaseType,
    dateRangeStart,
    dateRangeEnd,
    sortDirection,
    take,
    skip,
    sortBy,
  ]);

  useEffect(() => {
    if (!productCategoryFilterOptions.length && isGrouped && !productCategoriesQueryData.loading) {
      productCategoriesQuery();
    }
  // eslint-disable-next-line
  },        []);

  const onPagingChange = (pageNumber: number) => {
    const itemsPerPage = take;
    const newSkip =  (pageNumber - 1) * itemsPerPage;
    setSkip(newSkip);
  };

  const handleProductCategoryChange = (selectedOption: ValueType<SelectOption, false>) => {
    const filterValue = (selectedOption as SelectOption)?.value;
    const processedFilterValue = filterValue ? +filterValue : undefined;

    // Return if product category unchanged
    if (processedFilterValue === productCategoryKey) {
      return;
    }

    const isBatch = true;
    const qsStatesToBeBatchUpdated: QueryStringStateDetails<any>[] = [
      setSkip(0, isBatch),
      setProductCategoryKey(processedFilterValue, isBatch),
      setPackageKeys(undefined, isBatch),
      setPackageNamesStringForHistoryHeader(undefined, isBatch),
    ];

    // If currently showing history of a release, clear date range end filter and set currentlyShowingHistoryOfARelease to false
    if (currentlyShowingHistoryOfARelease) {
      qsStatesToBeBatchUpdated.push(
        setDateRangeEnd(null, isBatch),
        setcurrentlyShowingHistoryOfARelease(false, isBatch),
      );
    }

    batchUpdate(...qsStatesToBeBatchUpdated);
  };

  const isSupplementalToPackageType = (isSupplemental: boolean) => {
    if (isSupplemental) return PackageType.Supplemental;
    return PackageType.Core;
  };

  const handleReleaseTypeChange = (selectedOption: ValueType<SelectOption, false>) => {
    const filterValue = (selectedOption as SelectOption)?.value;
    const processedFilterValue = filterValue ? filterValue as ReleaseType : null;
    if (processedFilterValue === releaseType) { return; }
    const isBatch = true;
    batchUpdate(
      setReleaseType(processedFilterValue, isBatch),
      setSkip(0, isBatch),
    );
  };

  const handleProductTypeChange = (selectedOption: ValueType<SelectOption, false>) => {
    const filterValue = (selectedOption as SelectOption)?.value;
    const processedFilterValue = filterValue ? JSON.parse(filterValue) : null;
    if (processedFilterValue === isSupplemental) { return; }
    const isBatch = true;
    batchUpdate(
      setIsSupplemental(processedFilterValue, isBatch),
      setSkip(0, isBatch),
    );

  };

  // tslint:disable-next-line:max-line-length
  const handleHistoryLinkClick = (productCategoryKey: number, publishedAtDate: Date, packageKeys: number[], packageNames: string[]) => {
    const publishedAt: Date = new Date(publishedAtDate);
    const packageNamesFiltered = (packageNames).filter((name) => name !== ''); // remove empty items from array

    let formattedPackageNames = packageNamesFiltered.toString().replace(/,/g, ' | ');
    if (formattedPackageNames.slice(-3, -1) === ' |') {// remove '|' character from end of string
      formattedPackageNames = formattedPackageNames.substring(0, formattedPackageNames.length - 3);
    }

    const isBatch = true;
    batchUpdate(
      setSkip(0, isBatch),
      setPackageNamesStringForHistoryHeader(formattedPackageNames, isBatch),
      setDateRangeEnd(publishedAt, isBatch),
      setDateRangeStart(null, isBatch),
      setProductCategoryKey(productCategoryKey, isBatch),
      setcurrentlyShowingHistoryOfARelease(true, isBatch),
      setPackageKeys(packageKeys, isBatch),
      );
  };

  const handleItemsPerPageChange = (selectedOption: ValueType<SelectOption, false>) => {
    const newTake = (selectedOption as SelectOption)?.value;
    const newTotalSkip = Math.floor(skip / +newTake) * +newTake;
    const isBatch = true;
    batchUpdate(
      setSkip(newTotalSkip, isBatch),
      setTake(+newTake, isBatch),
    );

  };

  const handleStartDateChange = (selectedOption: ValueType<Date, false>) => {
    let startDate = selectedOption as Date | null;
    if (startDate && isNaN(startDate.getTime())) {// NaN if invalid date
      startDate = null;
    }
    const isBatch = true;
    batchUpdate(
      setDateRangeStart(startDate, isBatch),
      setSkip(0, isBatch),
    );

  };

  const handleEndDateChange = (selectedOption: ValueType<Date, false>) => {
    let endDate = selectedOption as Date | null;

    if (endDate && isNaN(endDate.getTime())) {// NaN if invalid date
      endDate = null;
    }
    const isBatch = true;
    batchUpdate(
      setDateRangeEnd(endDate, isBatch),
      setSkip(0, isBatch),
    );
  };

  const generateColumns: () => ColumnConfig[] = () => {
    const getOppositeSortDirection = (currentSortDirection: SortDirection): SortDirection => {
      return currentSortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    };

    const columns: ColumnConfig[] = [
      {
        headerKey: 'release.publishedAt',
        name: (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <span>Date Published</span>
          </div>
        ),
        isSortable: true,
        onSortingClick: (newSortDirection: SortDirection | undefined) => {
          if (!newSortDirection) {
            // tslint:disable-next-line:no-parameter-reassignment
            newSortDirection = getOppositeSortDirection(sortDirection);
          }
          setSortBy('release.publishedAt');
          setSortDirection(newSortDirection);
          setSkip(0);
        },
      },
      {
        headerKey: 'productCategory',
        name: (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <span>Product Category</span>
          </div>
        ),
      },
      {
        headerKey: 'packageName',
        name: (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <span>Product</span>
          </div>
        ),
      },
      {
        headerKey: 'isSupplemental',
        name: (
          <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left' }}>
            <span>
              <FaInfoCircle
                data-testid="productTypeInfoIcon"
                className={styles.info}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleModalVisibility();
                }}
              />
              Product Type
            </span>
          </div>
        ),
      },
      {
        headerKey: 'archive.releaseType',
        name: (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <span>Frequency</span>
          </div>
        ),
      },
      {
        headerKey: 'archive.deliverableName',
        name: (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <span>Deliverables</span>
          </div>
        ),
      },
    ];

    return columns;
  };

  const generateReleaseRow: (releaseData: ReleaseData) => ReactElement[] = (releaseData) => {
    const productType: ProductType = ProductType.Core;
    const fileIcon = releaseData.deliverable.type === DeliverableType.Notes ? <FaFilePdf /> : <RiFileExcel2Fill />;

    const releaseDataRow: ReleaseDataRow = {
      publishedAt:
      props.showHistory ?
      <div>{formatDate(releaseData.release.publishedAt)}<br></br>
        <button data-testid={`release-history-link:${releaseData.id}`} className={styles.history}
          onClick={() =>
            // tslint:disable-next-line:max-line-length
            { handleHistoryLinkClick(releaseData.productCategory.id, releaseData.release.publishedAt, [releaseData.packageKey], [releaseData.packageName]); }}>
              History
        </button>
      </div> : formatDate(releaseData.release.publishedAt),
      productCategory: releaseData.productCategory.name,
      // tslint:disable-next-line: object-shorthand-properties-first
      productType,
      releaseFrequency: releaseData.release.releaseType,
      release: releaseData.packageName,
      fileLink: releaseData.file ? (
        <FileDownloadLink releaseLogKey={releaseData.id}>
          {fileIcon}
          {releaseData.file.originalFilename}
        </FileDownloadLink>
      ) : (
        <></>
      ),
    };

    return Object.keys(releaseDataRow).map((key: string) => <span key={releaseData.id}>{releaseDataRow[key]}</span>);
  };

  const generateReleaseRows: (releases: ReleaseData[]) => ReactElement[][] = () => {
    return Array.isArray(releases) ? releases.map((release: ReleaseData) => generateReleaseRow(release)) : [];
  };

  const generateGroupedReleaseRow: (releaseData: FormattedGroupedReleaseData) => ReactElement[] = (releaseData) => {
    const releaseDataRow: GroupedReleaseDataRow = {
      publishedAt:
      props.showHistory ?
      <div>{formatDate(releaseData.release.publishedAt)}<br></br>
        <button data-testid={`release-history-link:${releaseData.id}`} className={styles.history}
          onClick={() =>
            // tslint:disable-next-line:max-line-length
            { handleHistoryLinkClick(releaseData.productCategory.id, releaseData.release.publishedAt, releaseData.packageKeys, releaseData.packageNames); }}
              >History
        </button>
      </div> : formatDate(releaseData.release.publishedAt),
      productCategory: releaseData.productCategory.name,
      release: releaseData.packageNames.map((pkgName, i) => (
        <div key={i}>{pkgName}</div>
      )),
      productType: releaseData.isSupplementals.map((isSupplemental, index) => {
        const key = `${releaseData.packageNames[index]}-${isSupplemental ? ProductType.Supplemental : ProductType.Core}`;
        return isSupplemental ? (
          <div key={key}>{ProductType.Supplemental}</div>
        ) : (
          <div key={key}>{ProductType.Core}</div>
        );
      }),
      releaseFrequency: releaseData.release.releaseType,
      fileLink: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflowX: 'auto' }}>
          {
            releaseData.files.map((file, i) => {
              if (file) {
                const fileExtension = releaseData.files[i]?.originalFilename.substring(releaseData.files[i]!.originalFilename.lastIndexOf('.') + 1) || undefined;
                const fileIcon = fileExtension === 'pdf' ? <FaFilePdf /> : <RiFileExcel2Fill />;
                // tslint:disable-next-line:max-line-length
                const fileNameWithoutExtension =
                  releaseData.files[i]?.originalFilename.substring(0, releaseData.files[i]!.originalFilename.lastIndexOf('.')) || file;
                const regExForFileName = /((- )|(-))?((\d{1, 2}) [A-Z]{3} (\d{2})?\d{2})/gi;
                const formattedFileName = fileNameWithoutExtension.toString().replace(regExForFileName, ' ');
                return (
                  <FileDownloadLink key={releaseData.ids[i]} releaseLogKey={releaseData.ids[i]}>
                    {fileIcon}
                    {formattedFileName}
                  </FileDownloadLink>
                );
              }
              return(<div key={`empty-${releaseData.ids[i]}`} style={{ height: '24px' }} />);
            })
          }
        </div>
      ),
    };

    return Object.keys(releaseDataRow).map((key: string) => <span key={releaseData.id}>{releaseDataRow[key]}</span>);
  };

  const sortReleaseData = (
    a: FormattedGroupedReleaseData,
    b: FormattedGroupedReleaseData,
    sortBy: string,
    sortDirection: SortDirection,
  ): number => {
    if (sortBy === 'release.publishedAt') {
      return sortDirection === SortDirection.DESC
        ? new Date(b.release.publishedAt).getTime() - new Date(a.release.publishedAt).getTime()
        : new Date(a.release.publishedAt).getTime() - new Date(b.release.publishedAt).getTime();
    }

    return sortDirection === SortDirection.DESC
      ? b.productCategory.name.localeCompare(a.productCategory.name)
      : a.productCategory.name.localeCompare(b.productCategory.name);
  };

  const generateGroupedReleaseRows: (groupedReleases: GroupedReleaseData[]) => ReactElement[][] = () => {
    return Array.isArray(groupedReleases)
      ? groupedReleases
          .reduce((data, currentValue: GroupedReleaseData) => {
            const existingReleaseRow = data.find(({ release }) => release.id === currentValue.release.id);
            if (!existingReleaseRow) {
              data.push({
                ...currentValue,
                ids: [currentValue.id],
                isSupplementals: [currentValue.isSupplemental],
                files: [currentValue.file],
                packageNames: [currentValue.packageName],
                deliverables: [currentValue.deliverable],
                packageKeys: [currentValue.packageKey],
              });
            } else {
              existingReleaseRow.ids.push(currentValue.id);
              existingReleaseRow.files.push(currentValue.file);
              if (!existingReleaseRow.packageNames.includes(currentValue.packageName)) {
                existingReleaseRow.packageNames.push(currentValue.packageName);
                existingReleaseRow.isSupplementals.push(currentValue.isSupplemental);
                existingReleaseRow.packageKeys.push(currentValue.packageKey);
              } else {
                // tslint:disable-next-line: quotemark
                existingReleaseRow.packageNames.push('');
              }
              existingReleaseRow.deliverables.push(currentValue.deliverable);
            }
            return data;
          },      [] as FormattedGroupedReleaseData[])
          .sort((a, b) => sortReleaseData(a, b, sortBy, sortDirection))
          .map((formattedGroupedRelease) => generateGroupedReleaseRow(formattedGroupedRelease))
      : [];
  };

  const generateArchiveRow: (archiveData: FormattedArchivesData) => ReactElement[] = (archiveData) => {
    const archiveDataRow: ArchiveDataRow = {
      publishedAt:
      props.showHistory ?
        <div>
          {formatDate(archiveData.publishedAt)}<br></br>
          <button data-testid={`archive-history-link:${archiveData.id}`} className={styles.history}
            onClick={() =>
              // tslint:disable-next-line:max-line-length
              { handleHistoryLinkClick(archiveData.productCategory.id, archiveData.publishedAt, archiveData.packageKeys, archiveData.packageNames); }}>
              History
          </button>
        </div>
        : formatDate(archiveData.publishedAt),
      productCategory: archiveData.productCategory.name,
      productName: archiveData.packageNames.map((pkgName, i) => (
        <div key={i}>{pkgName}</div>
      )),
      productType: archiveData.packageTypes.map((packageType, i) => {
        const key = `${archiveData.packageNames[i]}-${packageType === PackageType.Supplemental ?
          PackageType.Supplemental : PackageType.Core}`;
        return packageType === PackageType.Supplemental ? (
          <div key={key}>{PackageType.Supplemental}</div>
        ) : (
          <div key={key}>{PackageType.Core}</div>
        );
      }),
      frequency: archiveData.package.frequency,
      filelink: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {
            archiveData.filenames.map((file, i) => {
              if (file) {
                const fileExtension = file.substring(file.lastIndexOf('.') + 1) || undefined;
                const fileIcon = fileExtension === 'pdf' ? <FaFilePdf /> : <RiFileExcel2Fill />;
                // tslint:disable-next-line:max-line-length
                const fileNameWithoutExtension = file.substring(0, file.lastIndexOf('.')) || file;
                const regExForFileName = /((- )|(-))?((\d{1, 2}) [A-Z]{3} (\d{2})?\d{2})/gi;
                const formattedFileName = fileNameWithoutExtension.replace(regExForFileName, ' ');
                return (
                  <ArchiveDownloadLink key={archiveData.ids[i]} id={archiveData.ids[i]}>
                    {fileIcon}{formattedFileName}
                  </ArchiveDownloadLink>
                );
              }
              return(<div key={`empty-${archiveData.ids[i]}`} style={{ height: '24px' }} />);
            })
          }
        </div>
      ),
    };

    return Object.keys(archiveDataRow).map((key: string) => <span key={archiveData.id}>{archiveDataRow[key]}</span>);
  };

  const sortArchiveData = (
    a: FormattedArchivesData,
    b: FormattedArchivesData,
    sortBy: string,
    sortDirection: SortDirection,
  ): number => {
    if (sortBy === 'release.publishedAt') {
      return sortDirection === SortDirection.DESC
        ? new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
        : new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime();
    }

    return sortDirection === SortDirection.DESC
      ? b.productCategory.name.localeCompare(a.productCategory.name)
      : a.productCategory.name.localeCompare(b.productCategory.name);
  };

  const generateArchiveRows: (archives: Archive[]) => ReactElement[][] = () => {
    return Array.isArray(archives)
      ? archives
          .reduce((data, currentValue: Archive) => {
            const existingArchiveRow = data.find(({ releaseId }) => releaseId === currentValue.releaseId);

            if (!existingArchiveRow) {
              data.push({
                ...currentValue,
                ids: [currentValue.id],
                packageTypes: [currentValue.package.packageType],
                packageNames: [currentValue.package.name],
                filenames: [currentValue.filename],
                packageKeys: [currentValue.packageKey],
              });
            } else {
              existingArchiveRow.ids.push(currentValue.id);
              if (!existingArchiveRow.packageNames.includes(currentValue.package.name)) {
                existingArchiveRow.packageNames.push(currentValue.package.name);
                existingArchiveRow.packageTypes.push(currentValue.package.packageType);
                /// existingArchiveRow.packageKeys.push(currentValue.package);
              } else {
                // tslint:disable-next-line: quotemark
                existingArchiveRow.packageNames.push('');
              }
              existingArchiveRow.filenames.push(currentValue.filename);
            }
            return data;
          },      [] as FormattedArchivesData[])
          .sort((a, b) => sortArchiveData(a, b, sortBy, sortDirection))
          .map((formattedGroupedArchive) => generateArchiveRow(formattedGroupedArchive))
      : [];
  };

  const generateAllRows: (groupedReleases: GroupedReleaseData[], archives: Archive[]) => ReactElement[][] = () => {
    let concatArray: ReactElement[][] = [];
    const archiveSection = generateArchiveRows(archives);
    concatArray = archiveSection;
    const releaseSection = generateGroupedReleaseRows(groupedReleases);
    concatArray = sortDirection === SortDirection.DESC ? releaseSection.concat(concatArray) : concatArray.concat(releaseSection);

    return concatArray;
  };

  const pageCount = Math.ceil((combinedReleaseCount) / take);
  const currentPage = skip === 0 ? 1 : Math.floor(skip / take) + 1;
  const isReleaseTableError = (isGrouped && combinedReleasesQueryData.error) || (!isGrouped && releaseData.error);
  const isReleaseTableLoading = (isGrouped && (combinedReleasesQueryData.loading || productCategoriesQueryData.loading))
    || (!isGrouped && releaseData.loading);
  const isNoReleasesFound = (isGrouped && !combinedReleaseCount) || (!isGrouped && !releaseData.data?.releaseLogsForClient.items.length);

  const getHeaderContent = () => {
    if (props.showHistory && currentlyShowingHistoryOfARelease) {
      return <h1>{`Releases History: ${packageNamesStringForHistoryHeader}`}</h1>;
    }  if (props.showHistory) {
      return <h1>Releases</h1>;
    }
    return null;
  };

  const renderError = () => (
    <InlineAlert type={InlineAlertType.DataError}>
      <p>Unable to retrieve releases from the server</p>
    </InlineAlert>
  );

  const renderNoReleases = () => (
    <InlineAlert type={InlineAlertType.Info}>
      <p>No releases found</p>
    </InlineAlert>
  );

  const renderReleaseTable = () => (
    <>
      {props.showHistory ? '' : <h3>Releases</h3>}
      <div className={styles.tableContainer}>
        <Table
          columns={generateColumns()}
          rows={isGrouped ? generateAllRows(groupedReleases, archives) : generateReleaseRows(releases)}
          defaultSortingOptions={{ key: sortBy, sortDir: sortDirection }}
        />
      </div>
      <Pagination
        current={currentPage}
        total={pageCount}
        onChange={onPagingChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        itemsPerPageValue={take}
      />
      <Modal
        data-testid="productTypeModal"
        size="lg"
        fade={false}
        backdrop={true}
        isOpen={isModalVisible}
        toggle={toggleModalVisibility}
      >
        <ModalHeader toggle={toggleModalVisibility}>Product Types</ModalHeader>
        <ModalBody>
          <h4>Core</h4>
          <p>
            Core files are standardized across all product categories, and come in the form of either a PDF
            report or excel file. The PDF report is a write-up that analyzes the trends found in GfK proprietary
            sell-out data, and is written by GfK analysts. Sell-in estimates are also found in the PDF report. The
            excel file only contains GfK sell-out data, with both historical data and forecasts.
          </p>
          <h4>Supplemental</h4>
          <p>
            Supplemental files are subsets of data found in the Fundamental files. Unlike Fundamental files, which
            are standardized, supplemental files contain data that is cut and formatted in different ways (e.g.
            pivot table, .csv, tables, charts etc.). Supplemental files typically have a specified focus (brands,
            regions, models etc.), whereas Fundamental files have a broader focus (overall market trends).
          </p>
        </ModalBody>
      </Modal>
    </>
  );

  const renderContent = () => {
    if (isReleaseTableError) {
      return renderError();
    }  if (isReleaseTableLoading) {
      return <Loading />;
    }  if (isNoReleasesFound) {
      return renderNoReleases();
    }
      return renderReleaseTable();

  };

  return (
    <>
      {getHeaderContent()}

      {shouldShowFilters && (
        <Filters
          // tslint:disable-next-line:max-line-length
          productCategoryValue={productCategoryKey ? productCategoryFilterOptions.find((option) => option.value === productCategoryKey.toString()) : null}
          startDateValue={dateRangeStart ? dateRangeStart : undefined}
          endDateValue={dateRangeEnd ? dateRangeEnd : undefined}
          productTypeValue={isSupplemental !== null ? { value: isSupplemental ? 'Supplemental' : 'Core', label: isSupplemental ? 'Supplemental' : 'Core' } : null}
          releaseTypeValue={releaseType != null ? { value: releaseType, label: releaseType } : null}
          onProductCategoryChange={handleProductCategoryChange}
          onReleaseTypeChange={handleReleaseTypeChange}
          onProductTypeChange={handleProductTypeChange}
          productCategoryFilterOptions={productCategoryFilterOptions}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
        />
      )}

      {renderContent()}
    </>
  );
};

export default ReleaseTable;
