import React from 'react';
import styles from './StatSection.module.scss';

interface StatItemProps {
  number: string;
  description: string;
}

const StatItem: React.FC<StatItemProps> = ({ number, description }) => {
  return (
    <div className={styles.statItem}>
      <h2>{number}</h2>
      <p>{description}</p>
    </div>
  );
};

export default StatItem;
