import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'src/components/system/Button/Button';
import styles from './AboutGfkModal.module.scss';

type AboutGfkModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

const AboutGfkModal: React.FC<AboutGfkModalProps> = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={styles.modal}>
      <ModalBody>
        <div className={styles.modal_item}>
          <h1>How GfK Boutique Research Supports Investment Firms</h1>
          <p>
            GfK Boutique leverages its proprietary point-of-sale (PoS) database,
            the largest in the world, to provide its clients with tangible data on consumer
            end-demand trends. Since 2002, Boutique has been the incumbent alternative-data provider
            for the buy-side, particularly in the Technology and Consumer Spaces, providing:
          </p>
          <ul>
            <li>À la carte data cuts and research reports from the world's largest PoS database, capturing
              over USD 1 trillion in annual consumer spending across 300 product categories.</li>
            <li>Access to 30+ analysts who synthesize, analyze, and forecast Boutique's PoS data in monthly
              reports and dashboards, illuminating consumer end-demand trends and adding their expert insight
              into product launches, product portfolio mix, ASP trends, inventory disconnects, and other key
              performance indicators.</li>
            <li>Weekly and monthly deliverables of model- and country-level data with access to raw data files
              for ingestion and systematic interpretation.</li>
          </ul>
        </div>
        <hr className={styles.modalContentSeparator}></hr>
        <div className={styles.modal_container}>
          <div className={styles.modal_item}>
            <h1>About GfK Boutique Research</h1>
            <ul>
              <li>Exclusive to buy-side firms, servicing many of the largest funds in the Technology and Consumer spaces.</li>
              <li>A global subsidiary of GfK, the world's fourth-largest market research organization.</li>
              <li>Main offices in Boston, New York City, London, and Hong Kong with local data-collection teams in countries
                represented within our panels.</li>
            </ul>
          </div>
          <div className={styles.modal_item}>
            <h1>The Power of GfK Data</h1>
            <ul>
              <li>Panels formed for manufacturers to better understand their end-consumer; over 2/3 of the
                companies in the coverage list utilize GfK data to inform their business decisions.</li>
              <li>Proprietary PoS data sourced directly through exclusive data-sharing agreements with 100,000+ unique retailers,
                resellers, distributors, VARs, mobile carriers, and online channels in 75+ countries.</li>
              <li>Correlations between GfK captured sell-out revenue and company reported revenue are provided for all forecasted brands,
                demonstrating a high level of historical accuracy.</li>
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AboutGfkModal;
