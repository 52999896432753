import React from 'react';

interface TableBodyProps {
}

const TableBody: React.FC<TableBodyProps> = (props) => {
  return (
    <tbody>
      {props.children}
    </tbody>
  );
};

export default TableBody;
