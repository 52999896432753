import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './Table.module.scss';
import { SortDirection } from '../../../enums';
import { ColumnConfig, SortingOptions } from './type';
import TableColumnHeader from './TableColumnHeader';

interface TableHeadProps {
  columns: ColumnConfig[];
  isHeaderSticky?: boolean;
  isFirstColumnSticky?: boolean;
  defaultSortingOptions?: SortingOptions;
  setSortingOptions?: (options: SortingOptions) => void;
  onRender?: (ref: any) => void;
}

function getNewSortDirection(
  sortColumnConfig: ColumnConfig,
  selectedSortDirection: SortDirection | undefined,
  resetSortDirection: boolean,
) {
  const primarySortDirection = sortColumnConfig.defaultSortDirection || SortDirection.DESC;
  const secondarySortDirection = (primarySortDirection === SortDirection.DESC) ? SortDirection.ASC : SortDirection.DESC;
  return !selectedSortDirection || resetSortDirection || selectedSortDirection === secondarySortDirection
    ? primarySortDirection
    : secondarySortDirection;
}

export const updateSortOfTable = ({
  heading,
  columns,
  resetSortDirection,
  selectedSortDirection,
  setSelectedSortKey,
  setSelectedSortDirection,
  setSortingOptions,
}: {
  heading: string;
  columns: ColumnConfig[];
  resetSortDirection: boolean;
  selectedSortDirection?: SortDirection;
  setSelectedSortKey: (sortKey: string) => void;
  setSelectedSortDirection: (newSortDirection: SortDirection) => void;
  setSortingOptions?: (options: SortingOptions) => void;
}) => {
  const sortColumnConfig = columns.find((c) => c.headerKey === heading);
  if (!sortColumnConfig || (!sortColumnConfig.isSortable && !sortColumnConfig.onSortingClick)) {
    return;
  }

  const newSortDirection = getNewSortDirection(sortColumnConfig, selectedSortDirection, resetSortDirection);

  if (sortColumnConfig.onSortingClick) {
    sortColumnConfig.onSortingClick(newSortDirection);
  }

  if (setSortingOptions) {
    setSortingOptions({
      key: heading,
      sortDir: newSortDirection,
    });
  }

  setSelectedSortKey(heading);
  setSelectedSortDirection(newSortDirection);
};

const TableHead: React.FC<TableHeadProps> = (props) => {
  const { columns, isHeaderSticky, isFirstColumnSticky, setSortingOptions, defaultSortingOptions, onRender } = props;
  const defaultSortDir = defaultSortingOptions ? defaultSortingOptions.sortDir : undefined;
  const defaultSortKey = defaultSortingOptions ? defaultSortingOptions.key : undefined;
  const [selectedSortDirection, setSelectedSortDirection] = useState<SortDirection | undefined>(defaultSortDir);
  const [selectedSortKey, setSelectedSortKey] = useState<string | undefined>(defaultSortKey);
  const trRef = useRef(null);

  useEffect(() => {
    if (trRef.current && onRender) {
      onRender(trRef.current);
    }
  },        [
    onRender,
  ]);

  const updateSort = (heading: string, resetSortDirection: boolean) => {
    updateSortOfTable({
      heading,
      resetSortDirection,
      columns,
      selectedSortDirection,
      setSelectedSortDirection,
      setSelectedSortKey,
      setSortingOptions,
    });
  };

  return (
    <thead>
      <tr className={styles.Table__HeaderRow} ref={trRef}>
        {columns.map((column: ColumnConfig, indx: number) => <TableColumnHeader
          name={column.name}
          headerKey={column.headerKey}
          className={classnames(
            column.className,
            indx === 0 && isFirstColumnSticky && styles.Table__HeaderRow__stickyFirstColumnHeader,
          )}
          isSticky={isHeaderSticky}
          compact={column.compact}
          key={column.headerKey}
          updateSort={updateSort}
          selectedSortKey={selectedSortKey}
          selectedSortDirection={selectedSortDirection}
          isSortable={column.isSortable || !!column.onSortingClick}
        />)}
      </tr>
    </thead>
  );
};

export default TableHead;
