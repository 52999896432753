import React from 'react';
import LayoutWrapper from 'src/components/system/LayoutWrapper/LayoutWrapper';
import StatItem from './StatItem';
import styles from './StatSection.module.scss';

const StatSection: React.FC = () => {
  return (
    <div className={styles.statSectionWrapper}>
      <LayoutWrapper>
        <div className={styles.statSection}>
          <StatItem number="100,000+" description="retailer, reseller and distributor partnerships (online and offline)" />
          <StatItem number="180 million+" description="SKUs tracked - capturing online and offline product sales" />
          <StatItem number="60+" description="countries covered - offering a global view" />
        </div>
      </LayoutWrapper>
    </div>
  );
};

export default StatSection;
