import { inputs } from '@ecosystem/newron-design-system';
import Select from '../system/Select/Select';
import React, { useEffect, useState } from 'react';
import { ValueType } from 'react-select/src/types';
import { StylesConfig } from 'react-select';
import { ReleaseType } from 'src/enums';
import { CalendarView, DateRangePicker } from '../DateRangePicker';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { FlexDirectionProperty } from 'csstype';
interface FiltersProps {
  productCategoryValue?: SelectOption | null;
  startDateValue: Date | undefined;
  endDateValue: Date | undefined;
  productTypeValue?: SelectOption | null;
  releaseTypeValue?: SelectOption | null;
  onProductCategoryChange: (selectedOption: ValueType<SelectOption, false>) => void;
  onReleaseTypeChange: (selectedOption: ValueType<SelectOption, false>) => void;
  onProductTypeChange: (selectedOption: ValueType<SelectOption, false>) => void;
  productCategoryFilterOptions: SelectOption[];
  onStartDateChange: (startDate: Date | null) => void;
  onEndDateChange: (startDate: Date | null) => void;
}
export type SelectOption = { value: string; label: string };

const selectStyles: StylesConfig<SelectOption, false> = {
  container: (providedStyles) => ({
    ...providedStyles,
    maxWidth: 230,
    width: '100%',
    marginRight: 4,
  }),
};

const Filters: React.FC<FiltersProps> = ({
  productCategoryValue,
  startDateValue,
  endDateValue,
  productTypeValue,
  releaseTypeValue,
  onStartDateChange,
  onEndDateChange,
  onProductCategoryChange,
  onReleaseTypeChange,
  onProductTypeChange,
  productCategoryFilterOptions = [],
}) => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isSmallScreen = viewportWidth <= 839;
  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },        []);

  const filtersStyle = isSmallScreen
    ? {
        display: 'flex',
        flexDirection: 'column' as FlexDirectionProperty,
        maxHeight: '550px',
        gap: '20px',
        backgroundColor: '#f5f5f5',
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingBottom: '120px',
        paddingTop: '20px',
        overflow: 'auto',
      }
    : {
        display: 'flex',
        flexDirection: 'row' as FlexDirectionProperty,
        gap: '20px',
        paddingBottom: '20px',
      };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  const filters = (
    <div style={filtersStyle}>
      <div style={isSmallScreen ? { width: '100%', minWidth: '300px', marginRight: 0, flexWrap: 'wrap' } : { width: '100%', maxWidth: '300px', marginRight: 4, flexWrap: 'wrap' }}>
        <DateRangePicker
          calendarView={CalendarView.Month}
          onStartRangeSelect={onStartDateChange}
          onEndRangeSelect={onEndDateChange}
          selectedDate={{ startDate: startDateValue, endDate: endDateValue }}
          isClearable
          direction={isSmallScreen ? 'column' : 'row'}
          dateFormat={inputs.MonthFormatType['MMM-yyyy']}
        />
      </div>
      <Select
        value={productCategoryValue}
        placeholder="Filter By Category"
        styles={selectStyles}
        options={productCategoryFilterOptions}
        onChange={onProductCategoryChange}
        isClearable
        inputId="1"
      />
      <Select
        value = {productTypeValue}
        placeholder="Filter By Product Type"
        styles={selectStyles}
        options={[
          { value: 'false', label: 'Core' },
          { value: 'true', label: 'Supplemental' },
        ]}
        onChange={onProductTypeChange}
        isClearable
        inputId="2"
      />
      <Select
        value = {releaseTypeValue}
        placeholder="Filter By Frequency"
        styles={selectStyles}
        options={[
          { value: ReleaseType.Weekly, label: 'Weekly' },
          { value: ReleaseType.BiWeekly, label: 'BiWeekly' },
          { value: ReleaseType.Monthly, label: 'Monthly' },
          { value: ReleaseType.Quarterly, label: 'Quarterly' },
        ]}
        onChange={onReleaseTypeChange}
        isClearable
        inputId="3"
      />
    </div>
  );

  return isSmallScreen ? (
    <>
      {/* tslint:disable-next-line:max-line-length */}
      <Button variant="outlined" color="primary" onClick={handleOpen} style={{ color: 'black', borderColor: 'black' }} >
        Filters
        {isPopoverOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
      </Button>
      <br />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isPopoverOpen ? 'top' : 'bottom',
          horizontal: 'left',
        }}
      >
        {filters}
      </Popover>
    </>
  ) : (
    filters
  );
};

export default Filters;
