import React, { useCallback } from 'react';
import * as generateDownloadUrl from '../../graphql/generateReleaseLogDownloadUrl';
import { useLazyQuery } from '@apollo/client';
import styles from './FileDownloadLink.module.scss';

interface Props {
  releaseLogKey: number;
}

const FileDownloadLink: React.FC<Props> = ({ releaseLogKey, children }) => {
  const [generateUrl] = useLazyQuery<generateDownloadUrl.ResultType, generateDownloadUrl.VariablesType>(
    generateDownloadUrl.query,
    {
      onCompleted: (data) => {
        window.location.href = data.generateReleaseLogDownloadUrl;
      },
    },
  );

  const handleClick = useCallback(() => {
    generateUrl({
      variables: {
        releaseLogKey,
      },
    });
  },                              [generateUrl, releaseLogKey]);

  return (
    <button className={styles.FileDownloadLink} onClick={handleClick}>
      {children}
    </button>
  );
};

export default FileDownloadLink;
