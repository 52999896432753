import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import styles from './EcosystemFooter.module.scss';

const FooterLinks: React.FC  = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 900);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },        []);

  return isMobile ? (
    <>
      <div>
        <Link
          data-test-id="privacy-policy"
          className={styles['EcosystemFooter__Wrapper__links__link']}
          to="/privacy-policy"
          target="_blank">Privacy Policy
        </Link>
      </div>
      <div>
        <a className={styles['EcosystemFooter__Wrapper__links__link']}
          href="https://www.gfk.com/en-us/gfk-united-states-privacy-notice"
          target="_blank"
          rel="noopener noreferrer">Do Not Sell My Personal Information
        </a>
      </div>
    </>
      ) : (
        <>
          <Link
            data-test-id="privacy-policy"
            className={styles['EcosystemFooter__Wrapper__links__link']}
            to="/privacy-policy"
            target="_blank">Privacy Policy
          </Link>
          <a className={styles['EcosystemFooter__Wrapper__links__link']}
            href="https://www.gfk.com/en-us/gfk-united-states-privacy-notice"
            target="_blank"
            rel="noopener noreferrer">Do Not Sell My Personal Information
          </a>
        </>
      );
};

const EcosystemFooter: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return <div className={styles.EcosystemFooter}>
    <LayoutWrapper>
      <div className={styles.EcosystemFooter__Wrapper}>
        <div className={styles.EcosystemFooter__Wrapper__copywrite}>
          <span className={styles['EcosystemFooter__Wrapper__copywrite__app-name']}>gfkboutique</span>
          <span className={styles['EcosystemFooter__Wrapper__copywrite__copyright']}>&#169;{currentYear} GfK. All rights reserved.</span>
        </div>
        <div className={styles.EcosystemFooter__Wrapper__links}>
          <FooterLinks />
        </div>
      </div>
    </LayoutWrapper>
  </div>;
};

export default EcosystemFooter;
