import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { GenerateReleaseLogDownloadUrl, GenerateReleaseLogDownloadUrlVariables } from './types/GenerateReleaseLogDownloadUrl';

export type VariablesType = GenerateReleaseLogDownloadUrlVariables;
export type ResultType = GenerateReleaseLogDownloadUrl;

export const query = gql`
  query GenerateReleaseLogDownloadUrl(
    $releaseLogKey: Int!
  ) {
    generateReleaseLogDownloadUrl(
      data: {
        releaseLogKey: $releaseLogKey
      }
    )
  }`;
