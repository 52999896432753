import React, { ReactElement } from 'react';

import { ColumnConfig, SortingOptions } from './type';
import TableHead from './TableHead';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCell from './TableCell';
import styles from './Table.module.scss';
import classnames from 'classnames';

interface TableProps {
  columns: ColumnConfig[];
  rows?: ReactElement[][];
  rowRenderer?: () => React.ReactNode;
  isHeaderSticky?: boolean;
  isFirstColumnSticky?: boolean;
  defaultSortingOptions?: SortingOptions;
  setSortingOptions?: (options: SortingOptions) => void;
  onRender?: (ref: any) => void;
  className?: string;
}

const Table: React.FC<TableProps> = (props) => {
  const {
    columns,
    rows,
    isHeaderSticky,
    isFirstColumnSticky,
    setSortingOptions,
    defaultSortingOptions,
    onRender,
    rowRenderer,
  } = props;
  let children = props.children;

  if (rows && !rowRenderer) {
    children = rows.map((row: ReactElement[], rowIndx: number) => (
      <TableRow key={`table-row-${rowIndx}`} isFirstColumnSticky={isFirstColumnSticky}>
        {row.map((field: ReactElement, colIndx: number) => (
          <TableCell key={`field-${colIndx}`} content={field} />
        ))}
      </TableRow>
    ));
  }

  if (!rows && rowRenderer) {
    children = rowRenderer();
  }

  return (
    <table className={classnames(styles.Table, props.className)}>
      <TableHead
        onRender={onRender}
        columns={columns}
        isFirstColumnSticky={isFirstColumnSticky}
        isHeaderSticky={isHeaderSticky}
        setSortingOptions={setSortingOptions}
        defaultSortingOptions={defaultSortingOptions}
      />
      <TableBody>{children}</TableBody>
    </table>
  );
};

export default Table;
