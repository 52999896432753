import React from 'react';

const VisitorMessage: React.FC = () => {
  return (
    <>
     <h1>Need access?</h1>
     <p>If you need access please email
      the Customer Success Team at <a href="mailto:technology.advisory@gfk.com">technology.advisory@gfk.com</a></p>
    </>
  );
};

export default VisitorMessage;
