import { ReleaseType } from '../../enums';

export const queryStringValueToNumber = (stringToConvert: string | null): number | undefined => {
  if (stringToConvert === null) return undefined;

  const num = parseInt(stringToConvert, 10);
  return isNaN(num) ? undefined : num;
};

export const numberToQueryStringValue = (numberToConvert : number | undefined) : string | undefined => {
  if (numberToConvert === undefined) return undefined;

  return numberToConvert.toString();
};

export const boolToQueryStringValue = (boolToConvert : boolean) : string | undefined => {
  if (boolToConvert === undefined || boolToConvert === null) return undefined;

  if (boolToConvert) return 't';

  return undefined;
};
export const queryStringValueToBool = (stringToConvert : string | null) : boolean  => {
  if (stringToConvert === 't') return true;

  return false;
};

export const dateTimeToQueryStringValue = (dateTime: Date | null): string | null => {
  if (!dateTime) return null;

  return dateTime.toISOString();
};

export const queryStringValueToDateTime = (dateTimeStr: string | null): Date | null => {
  if (!dateTimeStr) return null;

  const dateTime = new Date(dateTimeStr);

  // Validate if the parsed string is a valid date.
  if (isNaN(dateTime.getTime())) {
    return null; // Invalid date
  }

  return dateTime;
};

export const isSupQsConverter = {
  fromString: (stringToConvert : string | null) : boolean | null => {
    if (stringToConvert === 't') return true;
    if (stringToConvert === 'f') return false;
    return null;
  },
  toString: (boolToConvert : boolean | null | undefined) : string | undefined => {
    if (boolToConvert === undefined || boolToConvert === null) return undefined;
    if (boolToConvert) return 't';
    return 'f';
  },
};

export const prodCatQsConverter = {
  fromString: queryStringValueToNumber,
  toString: numberToQueryStringValue,
};

export const releaseTypeQsConverter = {
  fromString: (stringToConvert : string | null) : ReleaseType | null => {
    if (Object.values(ReleaseType).includes(stringToConvert as ReleaseType)) {// ensure arg is a ReleaseType
      return stringToConvert as ReleaseType;
    }
    return null;
  },
  toString: (releaseTypeToConvert : ReleaseType | null) : string | undefined => {
    if (releaseTypeToConvert === null) return undefined;
    return releaseTypeToConvert;
  },
};

export const dateTimeQsConverter = {
  fromString: queryStringValueToDateTime,
  toString: dateTimeToQueryStringValue,
};

export const boolQsConverter = {
  fromString: queryStringValueToBool,
  toString: boolToQueryStringValue,
};

export const packageKeysQsConverter = {
  fromString: (stringToConvert: string | null): number[] | undefined => {
    const MAX_INPUT_LENGTH = 80;
    const MAX_ARRAY_LENGTH = 12;
    if (stringToConvert === null || stringToConvert.length > MAX_INPUT_LENGTH) return undefined;

    // parse and then filter out NaN values.
    const numberArray = stringToConvert.split(',')
      .map(part => parseInt(part, 10))
      .filter(num => !isNaN(num));

    if (numberArray.length > MAX_ARRAY_LENGTH || numberArray.length === 0) return undefined; // check for empty array

    return numberArray;
  },
  toString: (numberArrayToConvert: number[] | undefined): string | undefined => {
    if (numberArrayToConvert === undefined || numberArrayToConvert.length === 0) return undefined;
    return numberArrayToConvert.join(',');
  },
};

export const headerQsConverter = {
  fromString: (stringToConvert: string | null): string | undefined => {
    if (stringToConvert === null) return undefined;
    return decodeURIComponent(stringToConvert);
  },
  toString: (headerToConvert: string | undefined): string | undefined => {
    if (headerToConvert === undefined) return undefined;
    return encodeURIComponent(headerToConvert);
  },
};

const MAX_SKIP_VALUE = 20000;
const MIN_SKIP_VALUE = 0;
const DEFAULT_SKIP_VALUE = MIN_SKIP_VALUE;
export const skipQsConverter = {
  fromString: (stringToConvert: string | null): number => {
    if (!stringToConvert) return MIN_SKIP_VALUE;
    const value = parseInt(stringToConvert, 10);
    if (isNaN(value) || value < MIN_SKIP_VALUE) return MIN_SKIP_VALUE;
    return Math.min(value, MAX_SKIP_VALUE);
  },
  toString: (numberToConvert: number): string | undefined => {
    if (numberToConvert === DEFAULT_SKIP_VALUE) return undefined;
    return numberToConvert.toString();
  },
};

export const takeQsConverter = {
  fromString: (stringToConvert: string | null): number => {
    const MAX_TAKE_VALUE = 50;
    const MIN_TAKE_VALUE = 10;
    if (!stringToConvert) return 10;
    const value = Math.abs(parseInt(stringToConvert, 10));
    if (isNaN(value) || value < MIN_TAKE_VALUE) return MIN_TAKE_VALUE;
    return Math.min(value, MAX_TAKE_VALUE);
  },
  toString: (numberToConvert: number): string => {
    return numberToConvert.toString();
  },
};
