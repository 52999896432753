import { useEffect, useState } from 'react';
import { Converter, QueryStringStateDetails } from './types';
import { getCurrentQueryString, getValueFromQueryString, makeQueryStringWithCurrentStateValue } from './utilities';

type QueryStringStateSetter<T> = (value: T, byUser?: boolean, isBatchArg?: boolean) => QueryStringStateDetails<T>;
/*
 A custom React hook that synchronizes a state variable with the browser's URL query string.
 - Utilizes a Converter object to handle serialization and deserialization of the state to/from the URL.
 - Makes use of two useEffects - one for catching state changes and one for catching changes to query string
 - uses state 'isStateChangedFromUser' to track whether the state was changed by the user directly interacting with the app.
   This allows the prevention of circular updates.

 When `isBatch` is true, calling the returned setter function does not immediately modify the browser’s history stack.
 Instead, it returns an object containing variables which can be passed into a centralized batch update function,
 ensuring all the state changes are accumulated into a single history entry.
*/

export function useQueryStringState<T>(
  initialValue: T,
  key: string,
  converter: Converter<T>,
): [T, QueryStringStateSetter<T>] {
  // Initialization logic
  const [state, _setState] = useState<T>(() => {
    const valueFromQueryString = getValueFromQueryString(key, converter);
    return (valueFromQueryString != null) ? valueFromQueryString : initialValue;
  });
  const [isStateChangedFromUser, setIsStateChangedFromUser] = useState<boolean>(false);
  const [isBatch, setIsBatch] = useState<boolean>(false);

  const setState = (value: T, isBatchUpdate: boolean = false, isByUser: boolean = true): QueryStringStateDetails<T> => {
    _setState(value);
    setIsStateChangedFromUser(isByUser);
    setIsBatch(isBatchUpdate);
    return {
      value,
      key,
      converter,
      isBatchUpdate,
    };

  };

  useEffect(() => {
    // on state change
    try {
      if (isStateChangedFromUser) {
        setIsStateChangedFromUser(false); // set back to false as default
        if (isBatch) { return; }
        const newQueryString = makeQueryStringWithCurrentStateValue(state, key, converter);
        const doesQueryStringNeedUpdating = (getCurrentQueryString() !== newQueryString);
        if (doesQueryStringNeedUpdating) {
          window.history.pushState({}, '', `?${newQueryString}`);
        }

      }
    } catch (error) {
      console.error('Error updating query string:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [state, isStateChangedFromUser]);

  useEffect(() => {
    // on queryString change
    // Update state on query string change, only if triggered directly by queryString change.
    if (!isStateChangedFromUser) {
      const doesStateNeedUpdating = (getCurrentQueryString() !== makeQueryStringWithCurrentStateValue(state, key, converter));
      if (doesStateNeedUpdating) {
        setState(getValueFromQueryString(key, converter) ?? initialValue, false, false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [window.location.search]);

  return [state, setState];
}
