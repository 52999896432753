import { useState, useEffect } from 'react';

const useCheckIfOnSmallWindow = (breakpoint = 1200) => {
  const [isOnSmallWindow, setIsOnSmallWindow] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsOnSmallWindow(window.innerWidth < breakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },        [breakpoint]);

  return isOnSmallWindow;
};

export default useCheckIfOnSmallWindow;
