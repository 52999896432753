import { useCallback, useEffect, useState } from 'react';

const useResponsiveMaxHeight = (tileState: string, elementRef: { current: { scrollHeight: number | null; } | null; }) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const calculateMaxHeight = useCallback(() => {
    if (elementRef?.current?.scrollHeight === null) {
        setMaxHeight(0);
        return;
    }
    if (elementRef.current) {
      const extraSpace = 20; // Additional space for padding
      setMaxHeight(tileState === 'expanded' ? elementRef.current.scrollHeight + extraSpace : 0);
    }
  },                                     [tileState, elementRef]);

  useEffect(() => {
    calculateMaxHeight();

    const handleResize = () => {
      calculateMaxHeight();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },        [calculateMaxHeight]);

  return maxHeight;
};

export default useResponsiveMaxHeight;
