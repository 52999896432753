import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import { useCallback, useMemo } from 'react';

import { CalendarView, InitialDateRangePickerValue, weekStartsOn } from '../DateRangePicker';
import { calculateInitialValue } from '../utils';

interface UseDateRangePickerStateReturnType {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateSelect: (date: Date | null) => void;
  onEndDateSelect: (date: Date | null) => void;
}

export type UseDateRangePickerParams = {
  calendarView: CalendarView;
  onStartRangeSelect: (start: Date | null) => void;
  onEndRangeSelect: (end: Date | null) => void;
  selectedDate?: InitialDateRangePickerValue;
};

export const useDateRangePickerState = (
  calendarView: CalendarView,
  onStartRangeSelect: (start: Date | null) => void,
  onEndRangeSelect: (end: Date | null) => void,
  selectedDate?: InitialDateRangePickerValue,
): UseDateRangePickerStateReturnType => {
  const { startDate, endDate } = calculateInitialValue(calendarView, selectedDate);

  const onStartDateSelect = useCallback(
    (date: Date | null) => {
      if (date === null) {
        return onStartRangeSelect(null);
      }

      if (calendarView === CalendarView.Month) {
        return onStartRangeSelect(startOfMonth(date));
      }

      onStartRangeSelect(startOfWeek(date, { weekStartsOn }));
    },

    [calendarView, onStartRangeSelect],
  );

  const onEndDateSelect = useCallback(
    (date: Date | null) => {
      if (date === null) {
        return onEndRangeSelect(null);
      }

      if (calendarView === CalendarView.Month) {
        return onEndRangeSelect(endOfMonth(date));
      }

      onEndRangeSelect(endOfWeek(date, { weekStartsOn }));
    },
    [calendarView, onEndRangeSelect],
  );

  return useMemo(
    () => ({
      startDate,
      endDate,
      onStartDateSelect,
      onEndDateSelect,
    }),
    [startDate, endDate, onStartDateSelect, onEndDateSelect],
  );
};
