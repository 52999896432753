import React from 'react';
import Column from './Column';
import styles from './TileGrid.module.scss';

const TileGrid: React.FC = () => {

  const tiles = [
    { id: 1, title: 'Expert-led Insights', content: 'GfK Boutique provides exclusive, reliable, and actionable market trend data through the world’s largest point-of-sale (PoS) database for consumer electronics and consumer durables.' },
    { id: 2, title: 'Cohesive Analysis', content: 'We provide the connection between company KPIs and our proprietary global data set.' },
    { id: 3, title: 'Actionable Data', content: 'We aggregate, synthesize, and analyze this buying behavior data into actionable insights, clearly presented in our periodic reports.' },
    { id: 4, title: 'Global Point-of-Sale (PoS) Data', content: 'Our data is collected in more than 60 countries around the world, to provide you with a global perspective on where the markets are headed in this quarter and beyond.' },
  ];

  const columns = [
    tiles.slice(0, 2),
    tiles.slice(2, 4),
  ];

  return (
    <div className={styles.grid}>
      {columns.map((columnTiles) => (
        <Column key={`column-${columnTiles.map((tile) => tile.id).join('-')}`}
          tiles={columnTiles}
        />
      ))}
    </div>
  );
};

export default TileGrid;
