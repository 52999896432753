import React from 'react';
import Button from '../system/Button/Button';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { getConfig } from 'src/config';

interface ResendTemporaryPasswordModalProps {
  isOpen: boolean;
  toggle: () => void;
  email: string;
}

const ResendTemporaryPasswordModal: React.FC<ResendTemporaryPasswordModalProps> = ({ isOpen, email, toggle }) => {
  const resendTempPassword = async (username: string) => {
    const config = getConfig();
    const prefix = config.adminAppUrl === 'localhost:3000' ? 'http://' : 'https://';
    const apiUrl = `${prefix}${config.adminAppUrl}/api/resendTemporaryPassword`;
    const email = username.trim();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }), // Ensure the payload is an object with an email property
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        // Log the response status and text for debugging
        const errorText = await response.text();
        console.error('Error:', response.status, response.statusText, errorText);
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
          <ModalBody>
            <div>
              <h1>Temporary Password</h1>
              <p>
                Your password cannot be reset as the account has a temporary password associated with it.
              </p>
              <p>
                Click below to get a temporary password sent to your email and be directed back to the login page.
              </p>
              <div onClick={() => setTimeout(() => { window.location.href = '/'; }, 500)}>
                <Button type="button" onClick={() => resendTempPassword(email)}>
                  Resend password
                </Button>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
  );
};

export default ResendTemporaryPasswordModal;
