import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { EditClient, EditClientVariables } from './types/EditClient';

export type VariablesType = EditClientVariables;
export type ResultType = EditClient;

export const query = gql`
  mutation EditClient(
      $title: String!
      $firstName: String!
      $lastName: String!
      $emailAddress: String!
      $contactNumber: String
    ) {
    editClient(data: {
      title: $title
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      contactNumber: $contactNumber
    }) {
      id
    }
  }`;
